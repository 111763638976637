<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
    class="pca_formAddCustomer"
    novalidate
    @submit="onSubmit"
    @submit.native.prevent
  >
    <a-card :title="isCustomerEdit ? $t('customer.edit_customer') : $t('customer.add')" class="shadow-sm">
      <fieldset :disabled="loadingForm">
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.store_name')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="storeName"
            >
              <a-input
                v-model.trim="form.storeName"
                :placeholder="$t('customer.store_name')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.store_type')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="storeType"
            >
              <a-select
                v-model="form.store_type"
                :placeholder="$t('customer.store_type')"
                show-arrow
                show-search
              >
                <!-- <a-spin v-if="loadingCountries" slot="notFoundContent" size="small" /> -->
                <a-select-option value="STORE" title="Store">
                  Store
                </a-select-option>
                <a-select-option value="RESELLER" title="Store">
                  Reseller
                </a-select-option>
                <a-select-option value="GUEST" title="Store">
                  Guest
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.shop_code')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="code"
            >
              <a-input
                v-model.trim="form.code"
                :placeholder="$t('customer.shop_code')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider />

        <!-- EMAIL, NAMA PELANGGAN & PASSWORD -->
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.name')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="namecustomer"
            >
              <a-input
                v-model.trim="form.namecustomer"
                :placeholder="$t('customer.name')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="$route.query.customer_id" :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              label="Username"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="username"
            >
              <a-input
                v-model.trim="form.username"
                placeholder="Username"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.email')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="email"
            >
              <a-input
                v-model.trim="form.email"
                :placeholder="$t('customer.email')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.phone')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="phone"
              :rules="{
                required: $route.query.customer_id ? false : true,
                message: $t('customer.rules.mobile'),
                trigger: 'change',
              }"
            >
              <a-input
                v-model.trim="form.phone"
                :placeholder="$t('customer.phone')"
                type="number"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="!isCustomerEdit" :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.password')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="password"
            >
              <a-input-password
                v-model="form.password"
                :disabled="loadingForm"
                autocomplete="off"
                :placeholder="$t('customer.password')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="!isCustomerEdit" :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.confirm_password')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="confirmpassword"
              :rules="{
                required: true,
                message: $t('customer.rules.confirm_password'),
                trigger: 'change',
              }"
            >
              <a-input-password
                v-model="form.confirmpassword"
                :disabled="loadingForm"
                autocomplete="off"
                :placeholder="$t('customer.confirm_password')"
              />
              <div v-if="form.confirmpassword && form.confirmpassword !== form.password" class="ant-form-explain" style="color: #f5222e">
                {{ $t('customer.rules.match_password') }}
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider />
        <!-- NEGARA, KOTA, KECAMATAN, KODEPOS, NAMA JALAN -->
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('distributor.country')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="country"
            >
              <a-select
                v-model="form.country"
                show-arrow
                show-search
                :filter-option="filterOption"
                :placeholder="`Select ${$t('distributor.country')}`"
              >
                <!-- <a-spin v-if="loadingCountries" slot="notFoundContent" size="small" /> -->
                <a-select-option v-for="country in countries" :key="country.value" :value="country.value" :title="country.label">
                  {{ country.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('distributor.city')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="city"
            >
              <a-select
                v-model="form.city"
                show-arrow
                show-search
                :filter-option="filterOption"
                :not-found-content="loadingCities ? undefined : null"
                :disabled="!(!!form.country)"
                :placeholder="`Select ${$t('distributor.city')}`"
                @change="onChangeCity"
                @focus="getCities"
              >
                <a-spin v-if="loadingCities" slot="notFoundContent" size="small" />
                <a-select-option v-for="city in cities" :key="city.value" :value="city.value" :title="city.label">
                  {{ city.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('distributor.subdistrict')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="subdistrict"
            >
              <a-select
                v-model="form.subdistrict"
                show-arrow
                show-search
                :filter-option="filterOption"
                :not-found-content="loadingSubdistrict ? undefined : null"
                :placeholder="`Select ${$t('distributor.subdistrict')}`"
                @change="onChangeDistrict"
                @focus="getSubdistrict"
              >
                <a-select-option
                  v-if="!form.city.length"
                  key="novalid"
                  value=""
                  disabled
                  class="text-dark"
                >
                  Harap Pilih Kota
                </a-select-option>
                <a-spin v-else-if="loadingSubdistrict && form.city.length" slot="notFoundContent" size="small" />
                <!-- <a-spin v-if="loadingSubdistrict && !!form.city" slot="notFoundContent" size="small" /> -->
                <a-select-option v-for="sub in subdistricts" :key="sub.value" :value="sub.value" :title="sub.label">
                  {{ sub.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('distributor.postal_code')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 7}"
              prop="postcode"
            >
              <a-input
                v-model.trim="form.postcode"
                :placeholder="$t('distributor.postal_code')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('Nama Jalan')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="line1"
            >
              <a-textarea
                v-model.trim="form.line1"
                :auto-size="{ minRows: 2, maxRows: 9 }"
                :placeholder="$t('Nama Jalan')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider />
        <a-row :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.category')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="category"
            >
              <a-select
                v-model="form.category"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                :not-found-content="loadingCategory ? undefined : null"
                :placeholder="$t('customer.category')"
                @focus="getCategory"
              >
                <a-spin v-if="loadingCategory" slot="notFoundContent" size="small" />
                <div>{{ $t('customer.category') }}</div>
                <a-select-option v-for="category in categories" :key="category.id" :value="category.id" :title="category.category_name">
                  {{ category.category_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <template v-if="roleName === 'business'">
          <a-row :gutter="16" class="ml-1">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customer.user_status')"
                :label-col="{span: 9}"
                :wrapper-col="{span: 15}"
                prop="user_status"
              >
                <a-switch
                  :checked="form.user_status"
                  @change="(val) => {
                    form.user_status = val
                  }"
                >
                  <template #checkedChildren>
                    <div class="text-center" style="min-width: 50px;" />Active
                  </template>
                  <template #unCheckedChildren>
                    <div class="text-center" style="min-width: 50px;" />Inactive
                  </template>
                </a-switch>
              </a-form-model-item>
            </a-col>
          </a-row>
        </template>
        
        <!---------- Payment Distributor ---------->
        <a-row v-if="roleName === 'warehouse'" :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.sales_code')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
            >
              <a-select
                v-model="form.sales_code"
                show-arrow
                show-search
                :filter-option="filterOption"
                :not-found-content="loadingSalesCode ? undefined : null"
                :placeholder="$t('customer.sales_code')"
                @focus="fetchSalesCode"
              >
                <a-spin v-if="loadingSalesCode" slot="notFoundContent" size="small" />
                <a-select-option v-for="sales in salesCode" :key="sales.id" :value="sales.id" :title="sales.salesName">
                  {{ `${sales.code} - ${sales.salesName}` }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider />
        <a-row v-if="roleName === 'warehouse'" :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.coin_usage')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="coin_flag"
            >
              <a-select
                v-model="form.coin_flag"
                show-arrow
                :placeholder="$t('customer.coin_usage')"
                @change="onChangeCoin"
              >
                <a-select-option
                  v-for="(opt, index) in coinFlagOptions"
                  :key="index"
                  :value="opt.value"
                >
                  {{ opt.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row v-if="roleName === 'warehouse'" :gutter="16" class="ml-1">
          <a-col :span="12">
            <a-form-model-item
              :label="$t('customer.payment_model')"
              :label-col="{span: 9}"
              :wrapper-col="{span: 15}"
              prop="payment_model"
            >
              <a-select
                v-model="form.payment_model"
                show-arrow
                show-search
                :filter-option="filterOption"
                :placeholder="$t('customer.payment_model')"
              >
                <a-select-option v-for="payment in paymentModel" :key="payment" :value="payment.value">
                  {{ payment.label }}
                </a-select-option>
              </a-select>
              <div class="information">
                jika pilih CBD maka hanya dapat CBD saja, jika pilih  COD maka dapat CBD & COD, jika CAD maka dapat seluruhnya
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <div v-if="roleName === 'warehouse' && form.payment_model === 'CAD'">
          <a-row :gutter="16" class="ml-1">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customer.setup_credit_limit')"
                :label-col="{span: 9}"
                :wrapper-col="{span: 15}"
                style="font-weight: 800;"
              />
            </a-col>
          </a-row>
          <a-row :gutter="16" class="ml-1">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customer.credit_limit')"
                :label-col="{span: 10}"
                :wrapper-col="{span: 14}"
                prop="credit_limit"
              >
                <a-input-number
                  v-model="form.credit_limit"
                  class="w-100"
                  :max="1000000000"
                  :formatter="value => format(value)"
                  :parser="value => parseLocaleNumber(value)"
                  :placeholder="$t('customer.credit_limit')"
                  :disabled="seller_id && isCreditUsage(seller_id)"
                />
                <div class="information">
                  nilai kredit yang dimiliki pelanggan
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="ml-1">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customer.transaction_limit')"
                :label-col="{span: 10}"
                :wrapper-col="{span: 14}"
                prop="transaction_limits"
              >
                <a-row :gutter="16">
                  <a-col :span="20">
                    <a-input-number
                      v-model="form.transaction_limits"
                      :min="1"
                      class="w-100"
                      :placeholder="$t('customer.transaction_limit')"
                      :disabled="seller_id && isCreditUsage(seller_id)"
                    />
                  </a-col>
                  <a-col :span="4">
                    Kali
                  </a-col>
                </a-row>
                <div class="information">
                  limit maksimal transaksi yang dilakukan dengan pembayaran tempo
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="ml-1">
            <a-col :span="12">
              <a-form-model-item
                :label="$t('customer.payment_limit')"
                :label-col="{span: 10}"
                :wrapper-col="{span: 14}"
                prop="term_value"
              >
                <a-row :gutter="16">
                  <a-col :span="20">
                    <a-input-number
                      v-model="form.term_value"
                      class="w-100"
                      :placeholder="$t('customer.payment_limit')"
                    />
                  </a-col>
                  <a-col :span="4">
                    Hari
                  </a-col>
                </a-row>
                <div class="information">
                  Batas waktu pembayaran tagihan
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <!-- END PAYMENT SECTION -->
        
        <!-- Distributor & Kode Sales items -->
        <div v-if="roleName === 'business'" class="titleDistributorList">
          List Distributor
        </div>
        <a-card v-if="roleName === 'business'" class="mb-3 shadow-sm">
          <a-card
            v-for="(formDistributor, idx) in form.distributor"
            :key="'formDistributor' + idx"
            tabindex="0"
            class="pr-4 mb-3 shadow-sm focus-within-1 formDistributor"
          >
            <a-button
              v-if="form.distributor.length > 1"
              type="primary"
              size="small"
              icon="close"
              tabindex="-1"
              :disabled="loadingForm"
              class="position-absolute top-0 right-0 mt-1 mr-1"
              @click="() => onRemoveDistributor(idx)"
            />
            <div class="w-100 d-flex">
              <div class="w-50">
                <a-row :gutter="16" class="ml-1">
                  <a-col :span="24">
                    <a-form-model-item
                      :label-col="{span: 9}"
                      :wrapper-col="{span: 15}"
                      :label="$t('customer.distributor')"
                      :prop="`distributor.${idx}`"
                      :rules="{
                        required: true,
                        trigger: 'change',
                        validator: distributorValidator
                      }"
                    >
                      <a-select
                        v-model="form.distributor[idx]"
                        show-search
                        allow-clear
                        :filter-option="filterOption"
                        :not-found-content="null"
                        placeholder="Cari distributor..."
                        :disabled="editForm.editDistributor.some(e => e.seller_id === form.distributor[idx])"
                        @search="fetchDistributor($event, idx)"
                        @change="setSelectedDistributor($event, idx)"
                      >
                        <template #suffixIcon />
                        <a-spin v-if="loadingDistributor" slot="notFoundContent" size="small" />
                        <!-- <a-select-option key="all" value="all">
                          {{ $t('customer.select_all') }}
                        </a-select-option> -->
                        <!-- <a-select-option v-if="form.distributor_name[idx]" :value="form.distributor[idx]">
                          {{ form.distributor_name[idx] }}
                        </a-select-option> -->
                        <a-select-option
                          v-for="dist in form.list_distributor[idx]"
                          :key="dist.id+idx"
                          :value="dist.id"
                          :title="dist.name"
                        >
                          {{ form.distributor_name[idx] || dist.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16" class="ml-1">
                  <a-col :span="24">
                    <a-form-model-item
                      :label-col="{span: 9}"
                      :wrapper-col="{span: 15}"
                      :label="$t('customer.sales_code')"
                    >
                      <a-select
                        v-model="form.sales_code[idx]"
                        show-arrow
                        show-search
                        :filter-option="filterOption"
                        :not-found-content="loadingSalesCode ? undefined : null"
                        :placeholder="$t('customer.sales_code')"
                        @focus="fetchSalesCode"
                      >
                        <a-spin v-if="loadingSalesCode" slot="notFoundContent" size="small" />
                        <a-select-option v-for="sales in salesCode" :key="`${sales.id}`" :value="sales.id" :title="sales.salesName">
                          {{ `${sales.code} - ${sales.salesName}` }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16" class="ml-1">
                  <a-col :span="24">
                    <a-form-model-item
                      :label="$t('customer.buyer_code')"
                      :label-col="{span: 9}"
                      :wrapper-col="{span: 15}"
                      :prop="`form.distributor_buyer_code_on_seller.${idx}`"
                    >
                      <a-input
                        v-model="form.distributor_buyer_code_on_seller[idx]"
                        :placeholder="$t('customer.buyer_code')"
                      />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <!-- PAYMENT SECTION DISTRIBUTOR-->
                <a-row :gutter="16" class="ml-1">
                  <a-col :span="24">
                    <a-form-model-item
                      :label="$t('customer.coin_usage')"
                      :label-col="{span: 9}"
                      :wrapper-col="{span: 15}"
                      :prop="`distributor_coin_flag.${idx}`"
                      :rules="{
                        required: true,
                        message: $t('customer.rules.coin_usage'),
                        trigger: 'change',
                      }"
                    >
                      <a-select
                        v-model="form.distributor_coin_flag[idx]"
                        show-arrow
                        @change="(e) => onChangeCoin(e, idx)"
                      >
                        <a-select-option
                          v-for="(opt, index) in coinFlagOptions"
                          :key="index"
                          :value="opt.value"
                        >
                          {{ opt.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16" class="ml-1">
                  <a-col :span="24">
                    <a-form-model-item
                      :label="$t('customer.payment_model')"
                      :label-col="{span: 9}"
                      :wrapper-col="{span: 15}"
                      :rules="{
                        required: true,
                        message: $t('Model pembayaran harus di isi'),
                        trigger: 'change',
                      }"
                    >
                      <a-select
                        v-model="form.distributor_payment_model[idx]"
                        show-arrow
                        show-search
                        :filter-option="filterOption"
                      >
                        <a-select-option v-for="payment in paymentModel" :key="payment" :value="payment.value">
                          {{ payment.label }}
                        </a-select-option>
                      </a-select>
                      <div class="information">
                        jika pilih CBD maka hanya dapat CBD saja, jika pilih  COD maka dapat CBD & COD, jika CAD maka dapat seluruhnya
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <template v-if="form.distributor_payment_model[idx] === 'CAD'">
                  <a-divider class="my-2" />
                  <a-row :gutter="16" class="ml-1">
                    <a-col :span="24">
                      <a-form-model-item
                        :label="$t('customer.setup_credit_limit')"
                        :label-col="{span: 9}"
                        :wrapper-col="{span: 15}"
                        style="font-weight: 800;"
                      />
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" class="ml-1">
                    <a-col :span="24">
                      <a-form-model-item
                        :label="$t('customer.credit_limit')"
                        :label-col="{span: 9}"
                        :wrapper-col="{span: 15}"
                        :prop="`distributor_credit_limit.${idx}`"
                        :rules="{
                          required: true,
                          trigger: 'change',
                          validator: limitKreditValidator,
                        }"
                      >
                        <a-input-number
                          v-model="form.distributor_credit_limit[idx]"
                          class="w-100"
                          :max="1000000000"
                          :formatter="value => format(value)"
                          :parser="value => parseLocaleNumber(value)"
                          :placeholder="$t('customer.credit_limit')"
                        />
    
                        <div class="information">
                          nilai kredit yang dimiliki pelanggan
                        </div>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" class="ml-1">
                    <a-col :span="24">
                      <a-form-model-item
                        :label="$t('customer.transaction_limit')"
                        :label-col="{span: 9}"
                        :wrapper-col="{span: 15}"
                        :prop="`distributor_transaction_limits.${idx}`"
                        :rules="{
                          required: true,
                          trigger: 'change',
                          validator: limitTransactionValidator,
                        }"
                      >
                        <a-input-number
                          v-model="form.distributor_transaction_limits[idx]"
                          :min="1"
                          class="w-100"
                          :formatter="value => format(value)"
                          :parser="value => parseLocaleNumber(value)"
                          :placeholder="$t('customer.transaction_limit')"
                        />
                        <div class="information">
                          limit maksimal transaksi yang dilakukan dengan pembayaran tempo
                        </div>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" class="ml-1">
                    <a-col :span="24">
                      <a-form-model-item
                        :label="$t('customer.payment_limit')"
                        :label-col="{span: 9}"
                        :wrapper-col="{span: 15}"
                        :rules="{
                          required: true,
                          message: $t('Jatuh tempo harus di isi'),
                          trigger: 'change',
                        }"
                      >
                        <a-row :gutter="16">
                          <a-col :span="20">
                            <a-input-number
                              v-model="form.distributor_term_value[idx]"
                              class="w-100"
                              :placeholder="$t('customer.payment_limit')"
                            />
                          </a-col>
                          <a-col :span="4">
                            Hari
                          </a-col>
                        </a-row>
                        <div class="information">
                          Batas waktu pembayaran tagihan
                        </div>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                <!-- END PAYMENT SECTION -->
                </template>
              </div>
              <div class="w-50">
                <a-row :gutter="16" class="ml-1">
                  <a-col :span="24">
                    <a-form-model-item
                      :label-col="{span: 9}"
                      :wrapper-col="{span: 15}"
                      :label="$t('fulfillment.warehouse')"
                      :prop="`warehouse.${idx}`"
                      :rules="{
                        required: true,
                        trigger: 'change',
                        validator: warehouseValidator
                      }"
                    >
                      <a-select
                        v-model="form.warehouse[idx]"
                        show-arrow
                        show-search
                        mode="multiple"
                        :filter-option="filterOption"
                        placeholder="Pilih gudang"
                        :disabled="!form.distributor[idx]"
                        @select="onSelectWarehouse($event, idx)"
                        @deselect="onDeselectWarehouse($event, idx)"
                      >
                        <a-select-option
                          v-for="(item, n) in form.list_warehouse[idx]"
                          :key="item.id+n"
                          :value="item.id"
                          :title="`${item?.name ?? '--'} - ${item?.address?.city || '--'}`"
                        >
                          {{ `${item?.name ?? '--'} - ${item?.address?.city || '--'}` }}
                        </a-select-option>
                      </a-select>
                      <a-checkbox
                        v-if="!(!form.distributor[idx])"
                        :name="`warehouse.${idx}`"
                        :disabled="!form.distributor[idx]"
                        :checked="form.warehouse[idx]?.length === form.list_warehouse[idx]?.length"
                        @change.prevent="onSelectAllWarehouse($event, idx)"
                      >
                        Pilih Semua Gudang
                      </a-checkbox>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-card>

          <div class="text-right">
            <a-button type="primary" :disabled="loadingForm" @click="onAddDistributor">
              {{ $t('utils.add') }}
            </a-button>
          </div>
        </a-card>
        <div class="bg-white position-sticky bottom-0 z-10 py-2 border-top text-right">
          <a-button
            type="primary"
            size="large"
            class="mr-2"
            ghost
            :disabled="loadingForm"
            @click="onBackToList"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="loadingForm"
            html-type="submit"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </fieldset>
    </a-card>
    {{ JSON.stringify(form.distributor) }}
  </a-form-model>
</template>

<script>
import { getArea } from '@/api/data'
import { getBusinessMetaData } from '@/api/business'
import { getWarehouseInternal } from '@/api/warehouse'
import {
  getCustomerDetail,
  getCustomerCategory,
  getDistributor,
  getDistributorPriceArea,
  getSeller,
  getBuyerPayment,
  getBuyerPaymentProvider,
  getPaymentProvider,
  getPaymentModel,
  getSalesCode,
  crudCustomer,
  updateCustomerStatus,
  deleteCustomerCart,
} from '@/api/channels/distributor'
import { rulesCustomer } from '@/data/rules'
import { useCurrency } from '@/composable/useCurrency'
import debounce from 'lodash/debounce'

const formDataCustomer = (data) => ({
  ...data,
  email: '',
  // mobile: '',
  storeName: '',
  namecustomer: '',
  username: '',
  store_type: 'STORE',
  password: '',
  confirmpassword: '',
  category: '', // undefined
  line1: '',
  line2: '',
  country: "228", /** @NOTE : Define value to Indonesia */
  city: '', // []
  subdistrict: null,
  postcode: '',
  city_distributor: [],
  distributor: [undefined],
  list_distributor: [[]],
  warehouse: [undefined],
  list_warehouse: [[]],
  warehouse_price_area_ids: [undefined],
  sales_code: [undefined],
  payment_model: 'CBD', // []
  currency: "IDR",
  credit_limit: '',
  transaction_limits: 1, // ''
  coin_flag: true,
  term_value: null,
  term_unit: 'Day(s)',
  user_status: null,
  phone: '',

  distributor_name: [''],
  distributor_payment_model: ['CBD'], // []
  distributor_currency: ['IDR'],
  distributor_credit_limit: [''],
  distributor_transaction_limits: [1], // ''
  distributor_coin_flag: [true],
  distributor_term_value: [''],
  distributor_term_unit: ['Day(s)'],
  distributor_buyer_code_on_seller: [''],
})

export default {
  setup(props, { emit }) {
    const { format, parseLocaleNumber } = useCurrency()
    return {
      format,
      parseLocaleNumber,
    };
  },
  data() {
    return {
      rules: rulesCustomer,
      categories: [],
      countries: [
        { value: "228", label: "Indonesia", code: "ID", currency_code: "IDR", currency_id: "111" },
      ],
      cities: [],
      subdistricts: [],
      distributors: [],
      salesCode: [],
      paymentModel: [
        {
          label: 'Cash Before Delivery',
          value: 'CBD',
        },
        {
          label: 'Cash On Delivery',
          value: 'COD',
        },
        {
          label: 'Cash After Delivery',
          value: 'CAD',
        },
      ], // []
      form: formDataCustomer({ sales_code: (this.$store.state?.user?.role_name || '').toLowerCase() === 'distributor' ? '' : [''] }),
      loadingForm: false,
      loadingCategory: true,
      loadingCities: true,
      loadingSubdistrict: true,
      loadingDistributor: false,
      loadingPaymentModel: true,
      loadingSalesCode: true,
      coinFlagOptions: [{ value: true, label: 'Ya' }, { value: false, label: 'Tidak' }],
      termUnitOptions: [
        { value: 'Day', label: 'Hari' },
        { value: 'Week', label: 'Minggu' },
        { value: 'Month', label: 'Bulan' }, // Month(s)
      ],
      editForm: {
        editDistributor: [],
        editPayment: [],
        editPaymentDistributor: [],
      },
      seller_id: '',
      priceAreaId: '',
      distributorPaymentProvider: [],
      existingDistributor: [],
      distributorValidator: (rule, value, callback) => {
        if (value) {
          return callback()
        }
        return callback('Distributor harus dipilih')
      },
      warehouseValidator: (rule, value, callback) => {
        const selectedIndex = rule.field.split('.')[1]
        if (this.form.warehouse[selectedIndex]?.length > 0) {
          return callback()
        }
        return callback('Gudang harus dipilih minimal 1')
      },
      limitKreditValidator: (rule, value, callback) => {
        const selectedIndex = rule.field.split('.')[1]
        if(this.isCreditUsage(this.form.distributor[selectedIndex])) {
          const limitValue = this.getCreditLimitValue(Number(selectedIndex))
          if (value >= limitValue) {
            return callback();
          }
          return callback(`Anda sedang memiliki tagihan dan nilai kredit limit anda saat ini: ${this.toCurrency(limitValue)}, tidak boleh lebih kecil dari nilai yang dimiliki pelanggan saat ini`);
        } else {
          return callback();
        }
      },
      limitTransactionValidator: (rule, value, callback) => {
        const selectedIndex = rule.field.split('.')[1]
        if(this.isCreditUsage(this.form.distributor[selectedIndex])) {
          const limitValue = this.getTransactionLimitValue(Number(selectedIndex))
          if (value >= limitValue) {
            return callback();
          }
          return callback(`Anda sedang memiliki tagihan dan nilai transaksi limit anda saat ini: ${(limitValue)}, tidak boleh lebih kecil dari nilai yang dimiliki pelanggan saat ini`);
        } else {
          return callback();
        }
      },
      isUseErp: false,
    }
  },
  computed: {
    roleName() {
      return (this.$store.state?.user?.restriction_base || '').toLowerCase()
    },
    isCustomerEdit() {
      return this.$route.query.customer_id
    },
  },
  watch: {
    'editForm.editDistributor': {
      immediate: true,
      deep: true,
      handler(value) {
        if (value?.length > 0) {
          this.setEditDistributor()
        }
      },
    },
    'editForm.editPaymentDistributor': {
      immediate: true,
      deep: true,
      handler(value) {
        if (value?.length > 0) {
          this.setEditPayment()
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.fetchBusinessMetaData()
      if(this.$route.query.customer_id) this.rules.email[0].required = false
      if ((this.$store.state?.user?.restriction_base || '').toLowerCase() === 'warehouse') {
        this.form.user_status = false
      } else {
        this.form.user_status = true
      }

      this.fetchSalesCode()

      const business_id = this.$route.query.business_id
        ? this.$route.query.business_id
        : this.$store.state.user.businessList.filter(val => val.warehouse_id === this.$route.query.warehouse_id)[0]?.business_id

      if (this.$route.query.warehouse_id) {
        await getWarehouseInternal({
          business_id,
          params: { warehouse_id: [this.$route.query.warehouse_id].join(',') },
        })
        .then(async ({ data: { data: response } }) => {
          if (response.length > 0) {
            this.seller_id = response[0].seller_id
            const sellerAreaId = response[0]?.address?.area_id
            const { query, params } = this.$route
            const { data: getPriceArea } = await getDistributorPriceArea({
              business_id: query.business_id,
              channel_id: params.id,
              area_id: sellerAreaId,
            })
            if (getPriceArea) {
              this.priceAreaId = getPriceArea.data
            } else {
              this.$notification.error({
                message: 'Terjadi kesalahan',
                description: 'Gagal saat mencari price area',
              })
            }
          }
        })
      }
      if (this.$route.query.customer_id) {
        this.loadingForm = true 

        await getCustomerDetail({
          customer_id: this.$route.query.customer_id,
          business_id,
        })
        .then(async ({ data }) => {
          const { params } = this.$route
          this.editForm.editDistributor = data?.distributor
          data?.distributor?.forEach(async distributor => {
            await getBuyerPayment({
              business_id,
              buyer_id: data?.customer_id,
              seller_id: distributor?.seller_id,
            })
            .then(({ data }) => this.editForm.editPayment.push(data.data))
            
            await getBuyerPaymentProvider({
              business_id,
              buyer_id: data?.customer_id,
              seller_id: distributor?.seller_id,
              channel_id: params.id,
            })
            .then(({ data }) => this.editForm.editPaymentDistributor.push(data.data))

            await getPaymentProvider({
              business_id,
              id: distributor?.seller_id,
              buyer_id: data?.customer_id,
              channel_id: params.id,
            })
            .then(({ data: { data: response } }) => {
              this.distributorPaymentProvider.push({
                seller_id: distributor?.seller_id,
                credit_limit_usage: response?.credit_limit?.credit_limit_usage,
              })
            })
          });
          this.form = {
            ...this.form,
            storeName: `${data?.user?.first_name} ${data?.user?.name}`,
            email: data?.user?.email,
            phone: data?.user?.phone,
            namecustomer: data?.customer_name,
            username: data?.user?.user_name,
            code: data?.store_code,
            country: data?.address?.countryId.toString(),
            subdistrict: data?.address?.areaId.toString(),
            city: data?.address?.cityId.toString(),
            postcode: data?.address?.codePos,
            category: data?.category_id,
            line1: `${data?.address?.addressLine1} ${data?.address?.addressLine2}`,
            store_type: data?.store?.type,
            user_status: data?.is_active,
          }
          this.getCities()
          this.getSubdistrict()
          this.getCategory()
        })
        .catch(() => {})
        .finally(() => { this.loadingForm = false })
      }
    },
    async fetchBusinessMetaData() {
      const businessId = this.$route.query.business_id
      await getBusinessMetaData({ business_id: businessId })
      .then(({ data: response }) => {
        response.forEach((item) => {
          if (item.meta_name === 'is_use_erp') {
            const isUseErp = JSON.parse(item.meta_value)
            this.rules = {
              ...this.rules,
              code: [{
                required: isUseErp,
                message: this.$i18n.t('customer.rules.shop_code'),
                trigger: 'blur',
              }],
            }
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    setEditPayment() {
      const isDistributor = this.roleName === 'warehouse'
      this.form.distributor_payment_model = []
      this.form.distributor_currency = []
      this.form.distributor_credit_limit = []
      this.form.distributor_credit_limit_raw = []
      this.form.distributor_transaction_limits = []
      this.form.distributor_transaction_limits_raw = []
      this.form.distributor_coin_flag = []
      this.form.distributor_term_value = []
      this.form.distributor_term_unit = []
      this.form.distributor_buyer_code_on_seller = []
      let paymentDistributors = []
      this.editForm.editDistributor.forEach(distributor => {
        const payment = this.editForm?.editPayment?.find(f => f?.seller_id === distributor?.seller_id) || {}
        const paymentDistributor = this.editForm?.editPaymentDistributor?.find(f => f?.seller_id === distributor?.seller_id) || {}
        let dataDistributor = {
          ...distributor,
          ...payment,
          ...paymentDistributor,
        }
        const indexSellerExist = paymentDistributors.findIndex((obj) => obj.seller_id === dataDistributor.seller_id)
        if (indexSellerExist >= 0) {
          const selectedIndexSeller = paymentDistributors[indexSellerExist]
          paymentDistributors[indexSellerExist] = {
            ...selectedIndexSeller,
            warehouse_id: selectedIndexSeller.warehouse_id.concat(dataDistributor.warehouse_id),
            price_area_id: selectedIndexSeller.price_area_id.concat(dataDistributor.price_area_id),
          }
        } else {
          const initWhsArr = distributor?.warehouse_id ? [distributor.warehouse_id] : []
          const initPriceAreaArr = distributor?.price_area_id ? [distributor.price_area_id] : []
          paymentDistributors.push({
            ...dataDistributor,
            warehouse_id: initWhsArr,
            price_area_id: initPriceAreaArr,
          })
        }
      })
      paymentDistributors.forEach((dataDistributor, index) => {
        if (isDistributor) {
          this.form.payment_model = dataDistributor?.term_of_payment?.id
          this.form.currency = 'IDR'
          this.form.credit_limit = dataDistributor?.credit_limit
          this.form.transaction_limits = dataDistributor?.multiple_bond_allowed
          this.form.coin_flag = dataDistributor?.coin_flag
          this.form.term_value = dataDistributor?.term_value
          this.form.term_unit = dataDistributor?.term_unit
        } else {
          this.form.distributor_payment_model.push(dataDistributor?.term_of_payment?.id)
          this.form.distributor_currency.push('IDR')
          this.form.distributor_credit_limit.push(dataDistributor?.credit_limit)
          this.form.distributor_credit_limit_raw.push(dataDistributor?.credit_limit)
          this.form.distributor_transaction_limits.push(dataDistributor?.multiple_bond_allowed)
          this.form.distributor_transaction_limits_raw.push(dataDistributor?.multiple_bond_allowed)
          this.form.distributor_coin_flag.push(dataDistributor?.coin_flag)
          this.form.distributor_term_value.push(dataDistributor?.term_value)
          this.form.distributor_term_unit.push(dataDistributor?.term_unit)
          this.form.distributor_buyer_code_on_seller.push(dataDistributor?.buyer_code_on_seller)
        }
        this.$forceUpdate()
      })
    },
    setEditDistributor() {
      const isDistributor = this.roleName === 'warehouse'
      this.form.distributor = []
      this.form.list_distributor = []
      this.form.warehouse = []
      this.form.list_warehouse = []
      this.form.warehouse_price_area_ids = []
      this.form.distributor_name = []
      this.form.sales_code = []
      let paymentDistributors = []
      this.editForm.editDistributor.forEach(distributor => {
        const payment = this.editForm?.editPayment?.find(f => f?.seller_id === distributor?.seller_id) || {}
        const paymentDistributor = this.editForm?.editPaymentDistributor?.find(f => f?.seller_id === distributor?.seller_id) || {}
        let dataDistributor = {
          ...distributor,
          ...payment,
          ...paymentDistributor,
        }
        const indexSellerExist = paymentDistributors.findIndex((obj) => obj.seller_id === dataDistributor.seller_id)
        if (indexSellerExist >= 0) {
          const selectedIndexSeller = paymentDistributors[indexSellerExist]
          paymentDistributors[indexSellerExist] = {
            ...selectedIndexSeller,
            warehouse_id: selectedIndexSeller.warehouse_id.concat(dataDistributor.warehouse_id),
            price_area_id: selectedIndexSeller.price_area_id.concat(dataDistributor.price_area_id),
          }
        } else {
          const initWhsArr = distributor?.warehouse_id ? [distributor.warehouse_id] : []
          const initPriceAreaArr = distributor?.price_area_id ? [distributor.price_area_id] : []
          paymentDistributors.push({
            ...dataDistributor,
            warehouse_id: initWhsArr,
            price_area_id: initPriceAreaArr,
          })
        }
      })
      paymentDistributors.forEach((dataDistributor, index) => {
        this.setSelectedDistributor(dataDistributor?.seller_id, index, true)
        this.form.distributor_name.push(dataDistributor?.seller_name)
        this.form.distributor.push(dataDistributor?.seller_id)
        this.existingDistributor.push(dataDistributor?.seller_id)
        this.form.list_distributor.push([{
          id: dataDistributor?.seller_id,
          name: dataDistributor?.seller_name,
        }])

        if (isDistributor) {
          this.form.sales_code = this.salesCode.find(f => f.code === dataDistributor?.sales_code)?.id
        } else {
          this.form.sales_code.push(this.salesCode.find(f => f.code === dataDistributor?.sales_code)?.id)
          this.form.warehouse.push(dataDistributor?.warehouse_id || [])
          let tempWhsAndPriceAreaArr = []
          dataDistributor?.warehouse_id?.forEach((whs, index) => {
            tempWhsAndPriceAreaArr.push({
              warehouse_id: whs,
              price_area_id: dataDistributor?.price_area_id[index],
            })
          })
          this.form.warehouse_price_area_ids.push(tempWhsAndPriceAreaArr)
        }
        this.$forceUpdate()
      })
    },
    onChangeCityDistributor(val) {
      this.distributors = []
      // this.loadingDistributor = true
      if (!val.length) {
        this.form.distributor = []
        this.form.distributor_name = []
        let paymentDistributors = []

        if (this.editForm.editDistributor) {
          this.editForm.editDistributor.forEach(distributor => {
          const payment = this.editForm?.editPayment?.find(f => f?.seller_id === distributor?.seller_id) || {}
          const paymentDistributor = this.editForm?.editPaymentDistributor?.find(f => f?.seller_id === distributor?.seller_id) || {}
            const dataDistributor = {
              ...distributor,
              ...payment,
              ...paymentDistributor,
            }

            paymentDistributors.push(dataDistributor)
          })
          paymentDistributors.forEach(dataDistributor => {
            this.form.distributor.push(dataDistributor?.seller_id)
          })
        }
      }
    },
    onChangeCoin(val, idx = null) {
      if (idx) {
        this.form.distributor_coin_flag[idx] = val
      } else {
        this.form.coin_flag = val
      }
    },
    onBackToList() {
      const { warehouse_id, workspace_id, business_id, sales_channel_id, channel_code } = this.$route.query
      setTimeout(() => {
        this.$router.push({
          name: 'customer-channel.customer-list-v2',
          query: {
            warehouse_id,
            workspace_id,
            business_id,
            sales_channel_id,
            channel_code,
          },
        })
      }, 500)
    },
    onAddDistributor() {
      this.form.distributor.push(undefined)
      this.form.list_distributor.push([])
      this.form.warehouse.push(undefined)
      this.form.list_warehouse.push([])
      this.form.warehouse_price_area_ids.push(undefined)
      this.form.sales_code.push(undefined)
      this.form.distributor_payment_model.push('CBD')
      this.form.distributor_currency.push('IDR')
      this.form.distributor_credit_limit.push('')
      this.form.distributor_transaction_limits.push(1)
      this.form.distributor_coin_flag.push(true)
      this.form.distributor_term_value.push('')
      this.form.distributor_term_unit.push('Day(s)')
      this.form.distributor_buyer_code_on_seller.push('')
      setTimeout(() => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })
        const el = document.querySelector(`.formDistributor:nth-of-type(${this.form.distributor.length})`)
        el && el.focus()
      }, 9)
    },
    onRemoveDistributor(idx) {
      if (this.existingDistributor.includes(this.form.distributor[idx])) {
        return this.$notification.error({
          message: "Tidak dapat menghapus distributor",
          description: "Aksi ini dibatasi, anda tidak dapat menghapus distributor yang sudah pernah ditambahkan sebelumnya",
        })
      }
      this.form.distributor.splice(idx, 1)
      this.form.list_distributor.splice(idx, 1)
      this.form.warehouse.splice(idx, 1)
      this.form.list_warehouse.splice(idx, 1)
      this.form.warehouse_price_area_ids.splice(idx, 1)
      this.form.sales_code.splice(idx, 1)
      this.form.distributor_name.splice(idx, 1)
      this.form.distributor_payment_model.splice(idx, 1)
      this.form.distributor_currency.splice(idx, 1)
      this.form.distributor_credit_limit.splice(idx, 1)
      this.form.distributor_transaction_limits.splice(idx, 1)
      this.form.distributor_coin_flag.splice(idx, 1)
      this.form.distributor_term_value.splice(idx, 1)
      this.form.distributor_term_unit.splice(idx, 1)
      this.form.distributor_buyer_code_on_seller.splice(idx, 1)
      document.activeElement.blur()
    },
    onChangeCity() { // val, option
      this.subdistricts = []
      this.loadingSubdistrict = true
      this.form.subdistrict = ''
    },
    onChangeDistrict() { // val, option
      const findSelectedSubdistrict = this.subdistricts?.find((obj) => obj.value === this.form.subdistrict)
      this.form.postcode = findSelectedSubdistrict?.postcode
    },
    async getCategory(page = 1) {
      this.loadingCategory = true
      await getCustomerCategory({
        business_id: this.$route.query.business_id,
        params: {
          page,
          limit: 100,
        },
      })
      .then(res => {
        const list = res?.data?.content
        this.categories = list
        this.loadingCategory = false
      })
      .catch(() => {})
      .finally(() => { this.loadingCategory = false })
    },
    async getCities() {
      if (this.loadingCities) {
        await getArea({
          businessId: this.$route.query.business_id,
          params: { parentId: this.form.country },
        })
        .then(res => {
          const list = res?.data?.list
          if (list) {
            this.cities = list
            this.loadingCities = false
          }
        })
        .catch(() => {})
      }
    },
    async getSubdistrict() {
      if (this.loadingSubdistrict && this.form.city) {
        await getArea({
          businessId: this.$route.query.business_id,
          params: { parentId: this.form.city },
        })
        .then(res => {
          const list = res?.data?.list
          if (list) {
            this.subdistricts = list
            this.loadingSubdistrict = false
          }
        })
        .catch(() => {})
      }
    },
    async setSelectedDistributor(value, index, isEdit) {
      let tempList = []
      const business_id = this.$route.query.business_id
      await getSeller({
        businessId: business_id,
        params: { seller_id: value },
      })
      .then(res => {
        const data = res?.data?.data
        if (data) {
          tempList = data
        }
      })
      .catch(() => {
      })
      .finally(() => {
        if (tempList.length > 0) {
          this.form.distributor[index] = value
        } else {
          this.form.distributor[index] = undefined
        }
        if (!isEdit) {
          this.form.warehouse[index] = []
        }
        this.form.list_warehouse[index] = tempList
        this.$forceUpdate()
      })
    },
    onSelectAllWarehouse: debounce(function (value, index) {
      if (value.target.checked && this.form.list_warehouse[index]?.length > 0) {
        this.form.list_warehouse[index].forEach((obj) => {
          this.onSelectWarehouse(obj.id, index)
        })
        const { name } = value.target
        this.$refs.ruleForm.clearValidate(name);
      } else {
        this.form.warehouse_price_area_ids[index] = []
        this.form.warehouse[index] = []
      }
      this.$forceUpdate()
    }, 100),
    async onSelectWarehouse(value, index) {
      const findWhs = this.form.list_warehouse[index].find((obj) => value === obj.id)
      const { query, params } = this.$route
      if (!findWhs) {
        this.form.warehouse[index] = this.form.warehouse[index].filter((obj) => obj !== value)
        return this.$notification.error({
          message: "Terjadi kesalahan",
          description: "Gagal saat mencari data gudang",
        })
      }
      await getDistributorPriceArea({
        business_id: query.business_id,
        channel_id: params.id,
        area_id: findWhs?.address?.area_id,
      })
      .then(({ data: getPriceArea }) => {
        if (this.form.warehouse_price_area_ids[index]?.length > 0) {
          const tempArr = this.form.warehouse_price_area_ids[index]
          const tempArrWhs = this.form.warehouse[index].concat(value)
          tempArr.push({
            warehouse_id: value,
            price_area_id: getPriceArea?.data || null,
          })
          this.form.warehouse_price_area_ids[index] = tempArr
          this.form.warehouse[index] = [...new Set(tempArrWhs)]
        } else {
          this.form.warehouse_price_area_ids[index] = [
            {
              warehouse_id: value,
              price_area_id: getPriceArea?.data || null,
            },
          ]
          this.form.warehouse[index] = [value]
        }
      })
      .catch((err) => {
        this.form.warehouse[index] = this.form.warehouse[index].filter((obj) => obj !== value)
        this.$notification.error({
          message: "Gagal memilih gudang",
          description: `Price area tidak ditemukan di gudang ${findWhs?.name ?? '--'} - ${findWhs?.address?.city || '--'}`,
        })
      })
      .finally(() => {
        this.$forceUpdate()
      })
    },
    onDeselectWarehouse(value, index) {
      this.form.warehouse_price_area_ids[index] = this.form.warehouse_price_area_ids[index].filter((obj) => obj.warehouse_id !== value)
    },
    fetchDistributor: debounce(async function (value, index) {
      this.loadingDistributor = true
      if (value) {
        const business_id = this.$route.query.business_id
        await getDistributor({
          headers: {
            'Business-Id': business_id,
          },
          params: {
            page: 1,
            size: 50,
            q: value,
          },
        })
        .then(res => {
          let list = res?.data?.data?.records?.filter((obj) => obj.address) || []
          if (index > 0) {
            const filteredSelectedDistributor = this.form.distributor.filter((obj) => obj)
            list = list.filter((obj) => !filteredSelectedDistributor.includes(obj.id))
          }
          if (list.length) {
            this.form.list_distributor[index] = list
          } else {
            this.form.list_distributor[index] = []
            this.$message.info('Data distributor tidak ditemukan')
          }
          this.loadingDistributor = false
        })
        .catch(() => {})
      } else {
        this.form.list_distributor[index] = []
        this.loadingDistributor = false
      }
    }, 500),
    async fetchPaymentModel() {
      if (this.loadingPaymentModel) {
        const { query, params } = this.$route
        const seller_id = "cc5dd213-0a4f-4fc8-bc4e-84614cdbbaae"
        await getPaymentModel({
          business_id: query.business_id,
          channel_id: params.id,
          seller_id,
        })
        .then(res => {
          const list = res?.data?.data?.term_of_payment
          if (list) {
            this.paymentModel = list
            this.loadingPaymentModel = false
          }
        })
        .catch(() => {})
      }
    },
    async fetchSalesCode() {
      if (this.loadingSalesCode) {
        await getSalesCode({
          business_id: this.$route.query.business_id,
          params: {
            page: 1,
            size: 500,
          },
        })
        .then(res => {
          const list = res?.data?.content
          if (list) {
            this.salesCode = list
            this.loadingSalesCode = false
          }
        })
        .catch(() => {})
      }
    },
    onChange() {
      // ???
      // if (value.includes('all')) {
      //   const selectArray = name === 'city' ? 'cities' : 'distributors'
      //   this.form[name] = this[selectArray]
      // }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { params, query } = this.$route
          
          const business_id = this.$route.query.business_id
            ? this.$route.query.business_id
            : this.$store.state.user.businessList.filter(
                val => val.warehouse_id === this.$route.query.warehouse_id,
              )[0]?.business_id
              
          const channel_id = params.id
          const {
            storeName,
            namecustomer,
            username,
            store_type,
            email,
            phone,
            password,
            confirmpassword,
            code,
            category,
            line1,
            line2,
            country,
            city,
            subdistrict,
            postcode,
            distributor,
            sales_code,
            payment_model,
            currency,
            credit_limit,
            transaction_limits,
            coin_flag,
            term_unit,
            term_value,
            distributor_payment_model,
            distributor_coin_flag,
            distributor_currency,
            distributor_credit_limit,
            distributor_transaction_limits,
            distributor_term_unit,
            distributor_term_value,
            distributor_buyer_code_on_seller,
            warehouse_price_area_ids,
          } = this.form

          const isDistributor = this.roleName === 'warehouse'
          const dataDistributor = isDistributor ? [ this.$store.state?.user?.id ] : distributor
          const [ first_name, ...restName ] = namecustomer.split(' ')
          const address = {
            line1,
            line2,
            country_id: country, // 228,
            area_id: subdistrict, // 2551,
            city_id: city, // 1123,
            postcode, // 12810,
          }
          const userData = {
            first_name,
            last_name: restName.join(' '),
            email,
            phone,
          }
          const requestBody = {
            buyer: {
              is_needconfirm: false,
              channel_id,
              user: {
                ...userData,
                identity_number: '',
                address,
                birth_place: '', // "padang"
                birth_date: '', // "2000-08-04T13:11:00.761Z"
              },
              code, // 'BYR162'
              ext_code: code, // '10001'
              address,
              password: password === '' ? undefined : password,
              store: {
                name: storeName,
                type: store_type,
                // url: [
                //   { url: "http://tokopedia.com/" }, // http://tokopedia.com/abc
                // ],
                url: null,
                npwp: "",
              },
              image_data: [],
              customer_category_id: category, // "0ad5a625-506b-4232-9d76-a319530f0d67"
              // @NOTE : Dari Pilih Distributor
              // sellers_id: [
              //   {
              //     // @NOTE : warehouse_id Dari mana datanya?
              //     warehouse_id: "ef54d345-95f9-483d-a5c2-ccee0a4ab5af", // NOTE : multiple
              //     seller_id: "cc5dd213-0a4f-4fc8-bc4e-84614cdbbaae",
              //     // @NOTE : sales_code / Kode Sales apa harus sama jumlah nya dgn Distributor
              //     sales_code: 101,
              //     // @NOTE : Kalo distributor
              //     payment: {
              //       term_of_payment: "CBD", // required
              //       coin_flag: false, // required | NOTE : init to true
              //       credit_limit: 2900000,
              //       multiple_bond_allowed: "1",
              //       currency: "IDR",
              //       term_unit: "Month(s)",
              //       term_value: "9",
              //     },
              //   },
              // ],
              // 
              sellers_id: dataDistributor.map((dist, idx) => {
                let warehouse_id = ''
                let payment = null
                if (isDistributor) {
                  payment = {
                    term_of_payment: payment_model,
                    coin_flag: coin_flag,
                    currency: currency,
                    credit_limit: payment_model === 'CAD' ? credit_limit : 0,
                    multiple_bond_allowed: payment_model === 'CAD' ? transaction_limits : "",
                    term_unit: payment_model === 'CAD' ? term_unit : "",
                    term_value: payment_model === 'CAD' ? term_value : "",
                  }
                } else {
                  payment = {
                    term_of_payment: distributor_payment_model[idx],
                    coin_flag: distributor_coin_flag[idx],
                    currency: distributor_currency[idx],
                    credit_limit: distributor_payment_model[idx] === 'CAD' ? (distributor_credit_limit[idx]) : 0,
                    multiple_bond_allowed: distributor_payment_model[idx] === 'CAD' ? (distributor_transaction_limits[idx]) : "",
                    term_unit: distributor_payment_model[idx] === 'CAD' ? (distributor_term_unit[idx] || 'Day(s)') : "",
                    term_value: distributor_payment_model[idx] === 'CAD' ? (distributor_term_value[idx]) : "",
                  }
                }
                if (isDistributor) {
                  warehouse_price_area_ids[idx] = [{
                    price_area_id: this.priceAreaId,
                    warehouse_id: query.warehouse_id,
                  }]
                }
                else {
                  warehouse_id = this.distributors.find(f => f.id === dist)?.warehouse_id
                }
                return {
                  // warehouse_id: warehouse_id || this.editForm?.editDistributor[0]?.seller_id,
                  warehouse_price_area_ids: warehouse_price_area_ids[idx],
                  seller_id: isDistributor ? this.seller_id : dist,
                  sales_code: this.salesCode.find(f => f.id === (isDistributor ? sales_code : sales_code[idx]))?.code || '',
                  buyer_code_on_seller: distributor_buyer_code_on_seller[idx] ?? undefined,
                  payment,
                }
              }),
            },
            user: {
              ...userData,
              channel_id,
              password,
              confirmpassword,
              store_channel_id: null,
              change_login_req: true,
              user_name: this.$route.query.customer_id
                ? username
                : email,
            },
          }
          this.loadingForm = true
          await crudCustomer({
            business_id,
            customer_id: this.isCustomerEdit
              ? this.$route.query.customer_id
              : undefined,
            data: { ...requestBody },
          })
          .then(async (res) => {
            const status = res?.data?.status
            if (status && status.toLowerCase() === 'ok') {
              this.$message.success('Pelanggan berhasil disimpan')

              if(!this.isCustomerEdit) {
                await updateCustomerStatus({
                  type: this.form.user_status ? 'activate' : 'deactivate',
                  customer_id: res?.data?.data_buyer?.id,
                })
                .then(() => this.onBackToList())
              } else {
                await deleteCustomerCart({
                  business_id,
                  buyer_id: this.$route.query.customer_id,
                  channel_id,
                })
                .then(() => this.onBackToList())
                .catch(() => {})
                .finally(() => { this.loadingForm = false })
              }
            } else {
              this.$message.error('Gagal simpan pelanggan')
            }
          })
          .catch(() => {})
          .finally(() => { this.loadingForm = false })
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    isCreditUsage(seller_id) {
      const findPayment = this.distributorPaymentProvider.find(f => f.seller_id === seller_id)
      if (findPayment) {
        return findPayment?.credit_limit_usage > 0 ? true : false
      } else {
        return false
      }
    },
    getCreditLimitValue(index) {
      const newArr = this.form?.distributor_credit_limit_raw
      if (newArr?.length > 0) {
        return newArr[index]
      }
      return 0
    },
    getTransactionLimitValue(index) {
      const newArr = this.form?.distributor_transaction_limits_raw
      if (newArr?.length > 0) {
        return newArr[index]
      }
      return 0
    },
  },
}
</script>

<style lang="scss">
  .ant-form-item-required[title="Kecamatan"] {
    margin-left: -13px;
    z-index: 3;
  }
  .titleDistributorList {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .information {
    line-height: 20px;
    font-size: 12px;
    color: #565656;
  }
</style>
